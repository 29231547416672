import { $doc } from '../utils/globals';


const initializeMap = ($maps = $('.js-map')) => {
	$maps.each((i, map) => {
		const $mapHolder = $(map).find('.map__holder')
		// const $locations = $(map).closest('section').find('.js-locations')
		const lat = parseFloat($mapHolder.data('lat'));
		const lng = parseFloat($mapHolder.data('lng'));
		const icoColor = $mapHolder.data('pin-color');
		const customStyles = window.templateObj.snazyy_styles;
		var infowindow = '';
		
		const icon = {
			path: "M7 0C3.13 0 0 3.13 0 7C0 12.25 7 20 7 20C7 20 14 12.25 14 7C14 3.13 10.87 0 7 0ZM7 9.5C5.62 9.5 4.5 8.38 4.5 7C4.5 5.62 5.62 4.5 7 4.5C8.38 4.5 9.5 5.62 9.5 7C9.5 8.38 8.38 9.5 7 9.5Z",
			fillColor: icoColor,
			fillOpacity: 1,
			strokeWeight: 0,
		    size: new google.maps.Size(14, 20),
		};

		//Set map options
		const mapOptions = {
			center: new google.maps.LatLng(lat, lng),
			zoom: 12.4,
			draggable: true,
			scrollwheel: true,
			panControl: false,
			zoomControl: false,
			mapTypeControl: false,
			scaleControl: false,
			streetViewControl: false,
			overviewMapControl: false,
			fullscreenControl: false,
			gestureHandling: 'cooperative',
			styles: JSON.parse(customStyles),
		};

		const gMap = new google.maps.Map($mapHolder[0], mapOptions);

		const marker = new google.maps.Marker({
			map: gMap,
			position: mapOptions.center,
			icon: icon
		});

		let pins = $('.js-pins');

		$(pins).each(function(index, el) {
			let mapLat = $(el).data('lat');
			let mapLng = $(el).data('lng');

			const myLatLng = { lat: mapLat, lng: mapLng };

			const marker = new google.maps.Marker({
				map: gMap,
				position: myLatLng,
				icon: icon
			});

			marker.addListener("click", (e) => {

				if ( infowindow != '' ) {
					infowindow.close()
				}

				const contentString = $(el).next('.js-info').html();

				infowindow = new google.maps.InfoWindow({
					content: contentString,
				});
				infowindow.open(map, marker);

			});

		});


		const contentString = $mapHolder.next('.location__info').html();

		infowindow = new google.maps.InfoWindow({
			content: contentString,
		});

		marker.addListener("click", () => {

			infowindow.open(map, marker);
		});


		// 	const contentString = $(this).next().html();


		// 	infowindow = new google.maps.InfoWindow({
		// 		content: contentString,
		// 	});

		// 	marker.addListener("click", () => {
		// 		infowindow.open(map, marker);
		// 	});
		// })


	})
}

initializeMap();
