import { $win } from '../utils/globals';
import isInViewport from '../utils/is-in-viewport';

const offset = $win.width() > 768 ? 150 : 75;

$win.on('scroll load', function () {
	const $animatedItem = $('.js-animate');

	animateEl($animatedItem, offset)
})

const animateEl = function($el, offset){
	let scrollTop = $win.scrollTop();

	$el.each(function(){
		const $this = $(this);
		const top = $this.offset.top;
		const delay = $this.data('delay') ? $this.data('delay') : 0;

		//Fade element once it is in the viewpoet
		if (isInViewport($this, offset)) {
			setTimeout(function() {
				$this.addClass('animated')
			}, delay);
		};
	})
}
