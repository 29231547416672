import { $win } from './globals';

const isInViewport = function($el, offset = 0) {
	let elementTop = $el.offset().top;
    let elementBottom = elementTop + $el.outerHeight();

    let viewportTop = $win.scrollTop();
    let viewportBottom = viewportTop + $win.height();

    return elementBottom > viewportTop && elementTop + parseInt(offset) < viewportBottom;
}

export default isInViewport;
