import 'slick-carousel';
import { $win, MOBILE_WIDTH } from "../utils/globals";

$('.js-slider-hero .slider__slides').each((i, slider) => {
	const $slider = $(slider);
	
	const slickSettings = {
		infinite: true,
		autoplay: true,
		autoplaySpeed: 10000,
		fade: true,
		rows: 0,
		draggable: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		dots: true,
	};

	const sl = $slider.slick(slickSettings);
});

$('.js-slider-service .slider__slides').each((i, slider) => {
	var $slider = $(slider);
	const slidesCount = $slider.find('.slider__slide').length;

	if (slidesCount < 4 && $win.width() > 1024) {
		$slider.addClass('slider-flexed');
	} else {
		const slickSettings = {
			infinite: false,
			autoplay: false,
			rows: 0,
			slidesToShow: 4,
			slidesToScroll: 1,
			dots: false,
			arrows: true,
			variableWidth: true,
			prevArrow: '<button type="button" class="slick__btn slick__btn--prev"> <svg width="12" height="21" viewBox="0 0 12 21" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M11.67 2.36998L9.9 0.599976L0 10.5L9.9 20.4L11.67 18.63L3.54 10.5L11.67 2.36998Z"/> </svg> <span>Prev</span> </button>',
			nextArrow: '<button type="button" class="slick__btn slick__btn--next"> <span>Next</span> <svg width="12" height="21" viewBox="0 0 12 21" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M-7.82013e-05 2.36998L1.76992 0.599976L11.6699 10.5L1.76992 20.4L-7.82013e-05 18.63L8.12992 10.5L-7.82013e-05 2.36998Z"/> </svg> </button>',
			responsive: [
			    {
			      breakpoint: 1501,
			      settings: {
			        slidesToShow: 3,
			      	variableWidth: false,
			      }
			    },
			    {
			      breakpoint: 1024,
			      settings: {
			        slidesToShow: 2,
			      	variableWidth: false,
			      	infinite: true,
			      }
			    },
			    {
			      breakpoint: 768,
			      settings: {
			        slidesToShow: 1,
			        variableWidth: false,
			      }
			    }
		    ]
		};

		const sl = $slider.slick(slickSettings);
	}
});


$('.js-slider-logos .slider__slides').each((i, slider) => {
	const $slider = $(slider);
	
	const slickSettings = {
		infinite: true,
		rows: 0,
		slidesToShow: 6,
		slidesToScroll: 1,
		arrows: false,
		dots: true,
		prevArrow: '<button type="button" class="slick-prev"><span class="sr-only">Previous</span></button>',
		nextArrow: '<button type="button" class="slick-next"><span class="sr-only">Next</span></button>',
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 4,
				}
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 2,
				}
			}
		]
	};

	const sl = $slider.slick(slickSettings);

	$win.on('resize orientationchange', () => {
		if ($win.width() >= MOBILE_WIDTH && !sl.hasClass('slick-initialized')) {
			$slider.slick(slickSettings);
		}
	})
});


$('.js-slider-reviews .slider__slides').each((i, slider) => {
	const $slider = $(slider);
	const $parent = $(slider).parent();
	let slickSettings;
	
	if (!$parent.hasClass('slider-reviews--alt')) {
		slickSettings = {
			autoplay: true,
			autoplaySpeed: 10000,
			slidesToShow: 1,
			slidesToScroll: 1,
			infinite: true,
			touchThreshold: 100,
			arrows: true,
			dots: false,
			prevArrow: '<button type="button" class="slick-prev"><span class="sr-only">Previous</span></button>',
			nextArrow: '<button type="button" class="slick-next"><span class="sr-only">Next</span></button>',
			responsive: [
				{
					breakpoint: MOBILE_WIDTH,
					settings: "unslick"
				}
			]
		};
	} else {
		slickSettings = {
			autoplay: true,
			autoplaySpeed: 10000,
			slidesToShow: 4,
			slidesToScroll: 1,
			infinite: true,
			touchThreshold: 100,
			arrows: true,
			dots: false,
			prevArrow: '<button type="button" class="slick-prev"><span class="sr-only">Previous</span></button>',
			nextArrow: '<button type="button" class="slick-next"><span class="sr-only">Next</span></button>',
			responsive: [
				{
					breakpoint: MOBILE_WIDTH,
					settings: "unslick"
				},
				{
					breakpoint: 1200,
					settings: {
						slidesToShow: 3,
					}
				}
			]
		};
	}

	const sl = $slider.slick(slickSettings);

	$win.on('resize orientationchange', () => {
		if ($win.width() >= MOBILE_WIDTH && !sl.hasClass('slick-initialized')) {
			$slider.slick(slickSettings);
		}
	})
});
