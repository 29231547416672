import { $doc } from '../utils/globals';

let ajaxLoading = false;

$doc.on('click', '.js-loadmore', function(event) {
	event.preventDefault();
	event.stopImmediatePropagation();

	const $nextPageBtn = $(this);
	const nextPageUrl  = $nextPageBtn.attr('href');

	if ( ajaxLoading ) {
		return;
	}

	ajaxLoading = true;

	$.ajax({
		url: nextPageUrl,
		method: 'GET',
		success: function(response) {
			const newPosts = $(response).find('.section__articles > ul').html();
			$('.section__articles > ul').append(newPosts);
			const hasNextPage = $(response).find('.section__actions .js-loadmore').length;
			if ( hasNextPage ) {
				$nextPageBtn.attr('href', $(response).find('.js-loadmore').attr('href'));
			} else {
				$nextPageBtn.parent().remove();
			}
		},
		error: function(error) {
			console.error('Something went wrong. Please, try again!');
		},
		complete: function() {
			ajaxLoading = false;
		}
	});
});
