import { $win, MOBILE_WIDTH } from '../utils/globals';
import 'sticky-kit/dist/sticky-kit.js';

let headerH;
let wpAdminH;
let offset;

$win.on('load', function(){
	headerH = $('.header .header__body').innerHeight();
	wpAdminH = ($('#wpadminbar').length) ? $('#wpadminbar').innerHeight() : 0;
	offset = headerH + wpAdminH + 15
})

$win.on('scroll load orientationchange', function(){
	const stickyEl = $('.js-sticky')

	if ($win.width < MOBILE_WIDTH || !stickyEl.length) {
		return;
	}

	initStickyEl(stickyEl, offset)
})

function initStickyEl($el, offset = 0){
	const scrollTop = $win.scrollTop();
	
	$el.each(function(){
		const $this = $(this);
		const $thisH = $this.outerHeight();
		const $parent = $this.parent();
		const $parentO = $parent.offset().top;
		const $parentH = $parent.height();

		if ($parentH <= $thisH) {
			$this.removeClass('is-sticky')
			return
		}

		const isSticky = scrollTop > $parentO - offset
		const hasReachedBottom = scrollTop > $parentO + $parentH - $thisH - offset;

		$this.toggleClass('is-sticky', isSticky )

		if (hasReachedBottom) {
			return
		}

		$this.css("transform", "translateY(" + (scrollTop - $parentO + offset) + "px)")
	});
}

if ($win.width() > 767) {
	var headerHeight = $('.header .header__body').outerHeight();

	$('.article__socials-inner').stick_in_parent({
		offset_top: headerHeight
	});	
}

