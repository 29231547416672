const $grid = $('.section__articles');

$(".section__filters").on('click', '.js-filter', function(event) {
	event.preventDefault();

	$(this).parent().addClass('active').siblings().removeClass('active');

	const filter = $(this).data('filter');

	$grid.isotope({
		itemSelector: '.gallery-item',
		layoutMode: 'masonry',
		percentPosition: "true",
		filter
	})
});

