/*
 * Polyfills
 */
import './polyfills/object-fit';

/*
 * Modules
 */

import './modules/init-css-vars';
import './modules/add-classes';
import './modules/nav';
import './modules/custom-select';
import './modules/custom-datepicker';
import './modules/custom-slider-range';
import './modules/init-slick';
import './modules/init-magnific-popup';
import './modules/init-map';
import './modules/on-click';
import './modules/init-wpcf7-classes';
import './modules/js-animate';
import './modules/js-sticky';
import './modules/load-more';
import './modules/isotope.pkgd';
import './modules/filter';
