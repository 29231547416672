import { $win, $body, TABLET_WIDTH } from "../utils/globals";
import { isMobile } from '../utils/is-mobile';
import classNames from '../utils/constant-names';

/* Open Navigation */
$('.js-nav-trigger').on('click', event => {
	event.preventDefault();
	event.stopPropagation();

	$body.toggleClass(classNames.ShowMainNav);
});

/* Close Sub-menus If Clicked Elsewhere */
$body.on('click', event => {
	const $target = $(event.target);

	if (!$target.parents('.header').length){
		$body.removeClass(classNames.ShowMainNav);
			$('.nav').find(`.${classNames.Open}`).removeClass(classNames.Open)
	};
});

/*Open Sub menus*/
$('.menu-item-has-children > a').on('click', function(e){
	let $parent = $(this).closest('li');
	let $subMenu = $(this).next();

	if (!$parent.hasClass(classNames.Open)) {
		e.preventDefault();

		$parent.addClass(classNames.Open)
			.siblings().removeClass(classNames.Open)

		// if ($win.width() < TABLET_WIDTH) {
			$subMenu.stop().slideDown();
				$parent.siblings().find('> ul').slideUp();
		// }
	}

	// if (isMobile) {
		
	// } else {
	// 	if ($win.width() < TABLET_WIDTH && !$parent.hasClass(classNames.Open)) {
	// 		e.preventDefault();
			
	// 		$subMenu.stop().slideDown();
				
	// 		$parent.addClass(classNames.Open)
	// 			.siblings().removeClass(classNames.Open)
	// 				.find('> ul').slideUp();
	// 	};
	// }
});
