import 'magnific-popup';

$('.js-mfp-article').on('click', function(e){
	let $content = $(this).parent().next();
	let removalDelay = 300;

	if ( $content.length ) {
		e.preventDefault();
		e.stopPropagation();

		$.magnificPopup.open({
			mainClass: 'mfp-inline',
			disableOn: 767,
			fixedContentPos: false,
			autoFocusLast: false,
			items: {
				src: $content
			},
			type: 'inline',
			removalDelay: removalDelay,
			fixedBgPos: false,
			overflowY: 'auto',
		});
	}
});

$('.js-mfp-article-project').on('click', function(e){
	let $content = $(this).parent().next();
	let removalDelay = 300;

	if ( $content.length ) {
		e.preventDefault();
		e.stopPropagation();

		$.magnificPopup.open({
			mainClass: 'mfp-inline mfp-inline-project',
			disableOn: 767,
			fixedContentPos: false,
			autoFocusLast: false,
			items: {
				src: $content
			},
			type: 'inline',
			removalDelay: removalDelay,
			fixedBgPos: false,
			overflowY: 'auto',
		});
	}
});


$('.js-mfp-video').magnificPopup({
	type: 'iframe',
	removalDelay: 300,
	preloader: false,
	fixedBgPos: false,
	overflowY: 'auto',
	mainClass: 'mfp-iframe',
});
