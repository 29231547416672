const $win = $(window);
const $doc = $(document);
const $body = $('body');
const $htmlBody = $('html, body');
const MOBILE_WIDTH = 768;
const TABLET_WIDTH = 1024;

export {
	$win,
	$doc,
	$body,
	$htmlBody,
	MOBILE_WIDTH,
	TABLET_WIDTH,
};
